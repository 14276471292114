var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex gap-3': !_vm.vertical,
    'd-flex flex-column gap-2': _vm.vertical,
  }},_vm._l((_vm.vm.referralMilestonesData),function(milestone,index){return _c('div',{key:index,class:{
      spacer: !_vm.vertical && _vm.vm.referralMilestonesData.length - 1 !== index,
      'd-flex flex-column': !_vm.vertical,
      'd-flex flex-row align-stretch gap-2': _vm.vertical,
    },style:({
      height: !_vm.vertical ? 'initial' : '110px',
    })},[_c('div',{class:{
        'd-flex align-center gap-3 mb-7': !_vm.vertical,
        'd-flex flex-column gap-2': _vm.vertical,
      }},[_c('v-img',{attrs:{"contain":"","max-height":!_vm.vertical ? '48' : '32',"max-width":!_vm.vertical ? '48' : '32',"src":require(("@/assets/icons/referral--" + (milestone.completed ? 'active' : 'block') + ".svg"))}}),(_vm.vm.referralMilestonesData.length - 1 !== index)?[(!_vm.vertical)?_c('v-progress-linear',{attrs:{"value":milestone.process}}):_c('div',{staticClass:"spacer align-self-center d-flex flex-column"},[_c('v-sheet',{attrs:{"height":((milestone.process) + "%"),"color":"primary","width":"3"}}),_c('div',{staticClass:"spacer primary",staticStyle:{"opacity":"0.3"}})],1)]:_vm._e()],2),_c('div',{class:{
        'd-flex flex-column': !_vm.vertical,
        'spacer d-flex justify-space-between': _vm.vertical,
      }},[_c('div',{staticClass:"d-flex flex-column gap-1"},[_c('div',{staticClass:"text-title1 mb-2"},[_vm._v("TIER "+_vm._s(index + 1))]),_c('div',{staticClass:"text-body4"},[_vm._v(" "+_vm._s(milestone.minInvite)+_vm._s(index === _vm.vm.referralMilestonesData.length - 1 ? '+' : (" - " + (_vm.vm.referralMilestonesData[index + 1].minInvite - 1)))+" invites ")]),_c('div',{staticClass:"text-body4"},[_vm._v(_vm._s(milestone.reward)+" $INK per invite")])]),_c('v-chip',{staticClass:"text-detail black--text align-self-start",class:{ 'mt-2': !_vm.vertical },staticStyle:{"height":"25px"},attrs:{"color":milestone.completed ? 'background-linear' : 'primary lighten-2'}},[_c('v-icon',{attrs:{"left":"","size":"16"}},[_vm._v(_vm._s(milestone.completed ? 'mdi-check' : 'mdi-information-outline'))]),_vm._v(" "+_vm._s(milestone.completed ? 'Completed' : 'Locking')+" ")],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }